import { useContext, useEffect } from "react";
import Cookies from "js-cookie";
import AppContext from "../../utils/AppContext";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import Navigation from "../../common/Navigation/Navigation";
import LiveUpdates from "../../common/Leads/LiveUpdates";

import './AuthRoute.scss';

export default function AuthRoute({ element, navigation, liveUpdates, noRedirect = false, superUserOnly, publicOnly }) {

  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();

  const { loggedIn, currentUser } = state;

  const isSuperUser = currentUser?.super_user;

  useEffect(() => {
    // If user is not logged in, redirect:
    if (loggedIn === false && !noRedirect && !publicOnly) {

      if (window.location.pathname !== '/') {
        navigate('/login?redirect_to=' + encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search));
      }
      else {
        navigate('/login');
      }
    }
    // If user is logged in, redirect if the route is for "public" (non-logged-in) users only:
    else if (loggedIn === true && publicOnly) {
      navigate('/');
    }
  }, [loggedIn, noRedirect, publicOnly, navigate]);

  useEffect(() => {
    if (superUserOnly && loggedIn && !isSuperUser) {
      navigate('/')
    }
  }, [superUserOnly, loggedIn, isSuperUser, navigate]);

  useEffect(() => {
    const getUser = async () => {
      if (!Cookies.get('pl__api_access_token')) {
        dispatch({ type: 'loggedIn/set', payload: false });
      }
      else {
        try {
          const { data } = await api.get('/users/me/');
          dispatch({ type: 'currentUser/set', payload: data });
        }
        catch {
          // if there is an issue fetching the current user, redirect to landing page:
          dispatch({ type: 'loggedIn/set', payload: false });
        }
      }
    }

    if (!currentUser) {
      getUser();
    }

  }, [currentUser, dispatch]);

  const renderElement = () => {
    if (navigation) {
      return (
        <div className='__auth-route-navigation'>
          <Navigation />
          <main className='main-content'>{ element }</main>
        </div>
      );
    }

    return element;
  }

  // for AuthRoutes, wait until loggedIn is set to true or false before rendering the element:
  if (loggedIn === null) {
    return null;
  }

  // if the user is logged in but route is public-facing only, do not render the element:
  if (loggedIn === true && publicOnly) {
    return null;
  }

  // if it's for super users only, but user is not super user, do not render it:
  if (superUserOnly && !isSuperUser) {
    return null;
  }

  // if the user is NOT logged in, do not show the content (if noRedirect and publicOnly are not set)
  if (loggedIn === false && !noRedirect && !publicOnly) {
    return null;
  }

  return (
    <>
      { renderElement() }
      { liveUpdates && <LiveUpdates /> }
    </>
  )
}
