import { useState } from "react";
import { DateTime } from "luxon";
import { IS_MAC } from "../../utils/envSettings";
import useMutateLeadNotes from "../../hooks/useMutateLeadNotes";

import Form from 'react-bootstrap/Form';
import Button from '../../common/Button/Button';

import TrashIcon from "../../assets/icons/TrashIcon";
import PencilIcon from "../../assets/icons/PencilIcon";

export default function LeadNote({ leadId, note, showToast, onCancelNewNote }) {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState('');
  const [saving, setSaving] = useState(false);

  const { updateLeadNote, deleteLeadNote, createLeadNote } = useMutateLeadNotes({ leadId });

  const onEditNote = () => {
    setEditing(true);
    setText(note.note_text);
  }
  
  const onSave = e => {
    // e is optional since onSave can come from saving with CTRL+Enter
    e?.preventDefault();
    setSaving(true);

    const data = {
      note_text: text
    }

    const onSuccess = () => {
      setSaving(false);
      onCancelNewNote?.();
      setEditing(false);
      showToast("Lead note saved");
    }

    if (note) {
      updateLeadNote.mutate({ data, id: note.id }, { onSuccess });
    }
    else {
      data.lead_id = leadId;  
      createLeadNote.mutate({ data }, { onSuccess });
    }
  }

  const onDeleteNote = () => {
    deleteLeadNote.mutate(note.id);
    showToast("Lead note deleted");
  }

  const onKeyDownTextArea = e => {
    if (e.key === 'Enter' && (IS_MAC ? e.metaKey : e.ctrlKey)) {
      e.stopPropagation();
      onSave();
    }
    else if (e.key === 'Escape') {
      onCancel();
    }
  }

  const onCancel = () => {
    if (!note) {
      onCancelNewNote();
    }
    else {
      setEditing(false);
    }
  }

  if (editing || !note) {
    return (
      <div className='editing-note'>
        <Form onSubmit={onSave}>
          <Form.Control as='textarea' autoFocus rows={3} maxLength={1000} value={text} onChange={e => setText(e.target.value)} onKeyDown={onKeyDownTextArea} data-save-shortcut='true' />
            <div className='cancel-save-note'>
              <Button className='cancel' onClick={onCancel} disabled={saving}>Cancel</Button>
              <Button className='confirm' type='submit' loading={saving}>Save</Button>
            </div>
        </Form>
      </div>
    );
  }

  const noteDate = DateTime.fromISO(note.date_added);
  const diff = DateTime.now().diff(noteDate, 'hours').hours;

  return (
    <div className='note'>
      <div className='note-actions'>
        <span className='note-date'>{ diff >= 24 ? noteDate.toLocaleString(DateTime.DATE_MED) : noteDate.toRelative() }</span>
        <Button className='edit' onClick={onEditNote}><PencilIcon /><span>Edit</span></Button>
        <Button className='delete' onClick={onDeleteNote}><TrashIcon /><span>Delete</span></Button>
      </div>
      <div>{ note.note_text }</div>
    </div>
  );
}
