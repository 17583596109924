import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getUsers = ({ queryKey }) => {
  const params = new URLSearchParams();

  const [, organizationId, categoryId] = queryKey;

  params.append('organization_id', organizationId);
  categoryId && params.append('category_id', categoryId);
  
  if (!organizationId) {
    console.warn('useUsers: missing organizationId');
    return;
  }

  return api.get(`/users/?${params.toString()}`).then(res => res.data);
}

export default function useUsers({ organizationId, categoryId, ...props }) {
  return useQuery({ queryKey: ['users', organizationId, categoryId || 0], queryFn: getUsers, ...props });
}
