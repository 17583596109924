import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import classNames from "classnames";

import ChevronIcon from "../../assets/icons/ChevronIcon";
import LEAD_FIELDS from "../../utils/leadFields";

import ArrowIcon from "../../assets/icons/ArrowIcon";
import './SortableColumn.scss';

export default function SortableColumn({ type, selected, sortDir, onChangeSort }) {

  const renderAscending = type === 'date_added'
  ? <div><ArrowIcon />Oldest first</div>
  : <><div><ArrowIcon /> Sort Ascending</div><div className='explanation'>A to Z</div></>

  const renderDescending = type === 'date_added'
  ? <div><ArrowIcon className='desc' />Newest first</div>
  : <><div><ArrowIcon className='desc' /> Sort Descending</div><span className='explanation'>Z to A</span></>

  return (
    <Dropdown className='__sortable-column'>
      <Dropdown.Toggle as={ColumnToggle}>
        <div className="toggle-overflow">
          <div className={classNames({ selected })}>
            <span className='label'>{ LEAD_FIELDS[type].label }</span>
            { selected && <ArrowIcon className={classNames('sort-direction', { desc: sortDir === 'desc' })} /> }
          </div>
          <ChevronIcon className='chevron' />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
          <Dropdown.Item as='button' onClick={() => onChangeSort(type, 'asc')} className={classNames({ active: sortDir === 'asc' && selected })}>
            { renderAscending }
          </Dropdown.Item>
          <Dropdown.Item as='button' onClick={() => onChangeSort(type, 'desc')} className={classNames({ active: sortDir === 'desc' && selected })}>
            { renderDescending }
          </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const ColumnToggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props}>
    { children }
  </button>
));
