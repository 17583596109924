import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthForm from "./AuthForm";

import './LoginSignup.scss';

export default function Login() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
      <section className='__login-signup'>
        <h1>Precious Leads</h1>
        <div className="form-language">
          <AuthForm type='login' />
        </div>
      </section>
    </GoogleOAuthProvider>
  )
}
