import React from "react";
import classNames from "classnames";
import { userColor, userFullName, userInitials } from "../../utils/userUtils";

import Dropdown from 'react-bootstrap/Dropdown';
import ChevronIcon from "../../assets/icons/ChevronIcon";

import './LeadDropdown.scss';

export default function LeadAssigneeDropdown({ users, onChange, lead, type }) {
  if (!users) {
    return <div>...</div>;
  }

  const selectedUser = users?.find(user => user.id === lead.assigned_user_id);

  return (
    <Dropdown className={classNames('__lead-dropdown', { [`__lead-dropdown-${type}`]: type })}>
      <Dropdown.Toggle as={Toggle} className='lead-toggle'>
        <div className="toggle-overflow">
          { selectedUser && <div className='user-color' style={{ backgroundColor: selectedUser ? userColor(selectedUser).code : 'transparent' }}>{ userInitials(selectedUser) }</div> }
          <div className={classNames('label', { unassigned: !selectedUser })}>{ userFullName(selectedUser) || 'Unassigned' }</div>
          <ChevronIcon className='chevron' />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        { users.map(user => (
          <Dropdown.Item key={user.id} as='button' active={selectedUser?.id === user.id } onClick={() => onChange(user.id)}>
            <div className='user-color' style={{ backgroundColor: userColor(user).code }}>{ userInitials(user) }</div>
            <div className='label'>{ userFullName(user) }</div>
          </Dropdown.Item>
        ))}
        <Dropdown.Item as='button' onClick={() => onChange(null)} active={!selectedUser}>
        <div className='user-color' />
          <div className='label'>Unassigned</div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const Toggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props}>
    { children }
  </button>
));
