import USER_COLORS from "./userColors";
import runes from "runes";

export const userColor = user => {
  if (!user) {
    return {
      name : 'gainsboro',
      code: '#dcdcdc',
    }
  }

  const colorsLength = USER_COLORS.length;

  // square of user.id is used since USER_COLORS are ordered in similarity.
  // This helps when two user_ids are too close:
  return USER_COLORS[(user.id * user.id) % colorsLength];
}

export const userInitials = user => {
  return (runes.substr(user?.first_name || '', 0, 1) + runes.substr(user?.last_name || '', 0, 1)) || '';
}

export const userFullName = user => {
  const firstName = user?.first_name || '';
  const lastName = user?.last_name || '';

  if (!lastName) {
    return firstName || '';
  }
  else if (!firstName) {
    return lastName;
  }

  return firstName + ' ' + lastName;
}
