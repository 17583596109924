import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getCustomFields = ({ queryKey }) => {
  const [, categoryId] = queryKey;

  if (!categoryId) {
    return null;
  }

  return api.get(`/custom-fields/?category_id=${categoryId}`).then(res => res.data);
}

export default function useCustomFields({ categoryId, ...props }) {
  return useQuery({ queryKey: ['custom-fields', categoryId], queryFn: getCustomFields, ...props });
}
