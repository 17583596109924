import { useCallback, useEffect, useRef } from "react";
import { apiCategorySocket } from "../utils/api";
import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

export default function useCategorySocket({ categoryId }) {
  const socketRef = useRef();
  const queryClient = useQueryClient();

  const onReceiveMessage = useCallback(event => {
    const data = JSON.parse(event.data);
    console.log("Message from server:", data);

    const socketTask = data.message.result;

    if (data?.message?.type === "lead_import_update") {
      if (data?.message?.status === 'SUCCESS') {
        queryClient.setQueriesData(['categories'], data => produce(data, draft => {
          let selectedCategory;

          // const selectedCategory = Array.isArray(data) ? data.find(category => category.id === categoryId) : (data.id === categoryId && draft);

          if (Array.isArray(data)) {
            selectedCategory = draft.find(category => category.id === categoryId);
          }
          else if (data.id === categoryId) {
            selectedCategory = draft;
          }

          if (selectedCategory) {
            const taskIndex = selectedCategory.unchecked_import_tasks?.findIndex(task => task.id === socketTask.id) ?? -1;

            if (taskIndex !== -1) {
              selectedCategory.unchecked_import_tasks[taskIndex] = socketTask;
            }
            else {
              if (!Array.isArray(selectedCategory.unchecked_import_tasks)) {
                selectedCategory.unchecked_import_tasks = [ socketTask ];
              }
              else {
                selectedCategory.unchecked_import_tasks.unshift(socketTask);
              }
            }
          }
        }));
      }
    }
  }, [queryClient, categoryId]);
  
  useEffect(() => {
    if (categoryId && !socketRef.current) {
      console.log('opening socket on category...', categoryId);
      socketRef.current = apiCategorySocket(categoryId);

      socketRef.current.onmessage = (event) => {
        onReceiveMessage(event);
      };
  
      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
  
      socketRef.current.onclose = () => {
        console.log("WebSocket closed.");
      };

      return (() => {
        if (socketRef.current?.readyState === socketRef.current?.OPEN) {
          socketRef.current.close();
          socketRef.current = null;
        }
      })
    }
  }, [categoryId, onReceiveMessage]);

  useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && (!socketRef.current || socketRef.current.readyState === socketRef.current.CLOSED)) {
        console.log("Visibility changed. Reconnecting to socket for category:", categoryId);
        socketRef.current = apiCategorySocket(categoryId);

        socketRef.current.onmessage = (event) => {
          onReceiveMessage(event);
        };

        socketRef.current.onclose = () => {
          console.log("WebSocket closed.");
        };
    
        return (() => {
          if (socketRef.current?.readyState === socketRef.current?.OPEN) {
            socketRef.current.close();
            socketRef.current = null;
          }
        })
      }
    })
  }, [categoryId, onReceiveMessage]);
}
