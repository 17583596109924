import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { userFullName } from "../../utils/userUtils";

import useCategory from "../../hooks/useCategory";
import useGlobalParams from "../../hooks/useGlobalParams";
import useMutateImportTask from "../../hooks/useMutateImportTask";
import useUsers from "../../hooks/useUsers";

import ReviewLeadImportModal from "./ReviewLeadImportModal";

import Button from "../Button/Button";
import Spinner from 'react-bootstrap/Spinner';

import './LeadImportNotifications.scss';

export default function LeadImportNotifications({ settingsPage }) {
  const { organizationId, businessId, categoryId } = useGlobalParams();

  const { data: category } = useCategory({ businessId, categoryId });
  const { data: users } = useUsers({ organizationId });
  const { markImportAsReadAsync } = useMutateImportTask({ categoryId });
  const [showReviewImport, setShowReviewImport] = useState(false);
  const [reviewId, setReviewId] = useState();

  const { unchecked_import_tasks } = category || {};

  const { pendingImports, successImports, errorImports } = useMemo(() => {
    const pendingImports = [];
    const successImports = [];
    const errorImports = [];

    unchecked_import_tasks?.forEach(task => {
      switch (task.state) {
        case 'PENDING':
        case 'STARTED':
          const lastUpdated = DateTime.fromISO(task.last_updated);
          const diff = DateTime.now().diff(lastUpdated, 'hours').hours;
          
          diff < 24 && pendingImports.push(task);
          break;

        case 'SUCCESS':
          task.leads_with_errors > 0 ? errorImports.push(task) : successImports.push(task);
          break;

        default:
          console.warn("no such task:", task.state);
      }
    });

    return { pendingImports, successImports, errorImports };
  }, [unchecked_import_tasks]);

  const onHideSuccessTasks = () => {
    successImports.forEach(task => {
      markImportAsReadAsync.mutate({ id: task.id });
    });
  }

  const onClickReview = id => {
    setReviewId(id);
    setShowReviewImport(true);
  }

  const onHideReview = () => {
    setReviewId(null);
    setShowReviewImport(false);
  }

  if (!category) {
    return;
  }

  if (pendingImports.length > 0) {
    const firstUserId = pendingImports[0].submitted_by_user_id;
    let userName = '';

    if (pendingImports.filter(task => task.submitted_by_user_id !== firstUserId).length > 0) {
      userName = 'started by multiple users';
    }
    else {
      const user = users?.find(user => user.id === firstUserId);
      
      if (user) {
        userName = `started by ${userFullName(user)}`;
      }
    }

    return (
      <div className='__lead-import-notifications'>
        <div className='notification-bar warning'>
          <Spinner />
          { settingsPage
          ? <>{ pendingImports.length === 1 ? 'A lead import' : `${pendingImports.length} lead imports` } { userName } { pendingImports.length === 1 ? 'is' : 'are' } in progress — certain actions will be unavailable until completion</>
          : <>{ pendingImports.length === 1 ? 'A lead import' : `${pendingImports.length} lead imports` } { userName } { pendingImports.length === 1 ? 'is' : 'are' } in progress — the list may be unstable until completion</>
          }
        </div>
      </div>
    )
  }

  const renderSuccessImports = () => {
    if (successImports.length === 1) {
      const user = users?.find(user => user.id === successImports[0].submitted_by_user_id);

      return (
        <div className='notification-bar success'>
          The import of {successImports[0].imported_leads.toLocaleString()} leads { user && `submitted by ${userFullName(user)}` } has finished successfully
          <Button className='action' onClick={onHideSuccessTasks}>Okay, got it!</Button>
        </div>
      );
    }

    else if (successImports.length > 1) {
      const totalLeads = successImports.reduce((total, { imported_leads }) => total + imported_leads, 0);
      
      let userName = '';
      
      const firstUser = successImports[0].submitted_by_user_id;

      if (successImports.filter(task => task.submitted_by_user_id !== firstUser).length > 0) {
        userName = 'submitted by multiple users';
      }
      else {
        const user = users?.find(user => user.id === successImports[0].submitted_by_user_id);

        if (user) {
          userName = `submitted by ${userFullName(user)}`;
        }
      }
      
      return (
        <div className='notification-bar success'>
          {successImports.length === 1 ? 'Lead import of' : 'Multiple lead imports totaling' } {totalLeads} leads {userName} finished successfully
          <Button className='action' onClick={onHideSuccessTasks}>Okay, got it!</Button>
        </div>
      );
    }
  }

  const renderErrorImports = () => (
    errorImports.map(task => {
      const user = users?.find(user => user.id === task.submitted_by_user_id);

      return (
        <div key={task.id} className='notification-bar error'>
          The lead import { user && `submitted by ${userFullName(user)}` } finished with some errors
          <Button className='action' onClick={() => onClickReview(task.id)}>Review errors</Button>
        </div>
      )
    })
  );

  return (
    <div className='__lead-import-notifications'>
      { renderSuccessImports() }
      { renderErrorImports() }
      <ReviewLeadImportModal show={showReviewImport} taskId={reviewId} onHide={onHideReview} />
    </div>
  );
}
