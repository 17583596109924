import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { api } from "../utils/api";

const apiCreate = ({ data }) => api.post(`/user-category-columns/`, { ...data }).then(res => res.data);
const apiUpdate = ({ data, id }) => api.put(`/user-category-columns/${id}/`, { ...data }).then(res => res.data);
const apiDelete = id => api.delete(`/user-category-columns/${id}/`).then(res => res.data);

export default function useMutateUserCategoryColumn({ categoryId }) {
  const queryClient = useQueryClient();

  const onCreate = requestData => {
    queryClient.setQueriesData(['user-category-columns', categoryId], data => produce(data, draft => {
      draft.unshift(requestData);
    }));
  }

  const onUpdate = requestData => {
    queryClient.setQueriesData(['user-category-columns', categoryId], data => produce(data, draft => {
      const index = data.findIndex(note => note.id === requestData.id);
      draft[index] = requestData;
    }));
  }

  const onDelete = id => {
    queryClient.setQueriesData(['user-category-columns', categoryId], data => produce(data, draft => {
      const index = data.findIndex(note => note.id === id);
      draft.splice(index, 1);
    }));
  }

  return {
    createUserCategoryColumn : useMutation({ mutationFn: apiCreate, onSuccess: onCreate }),
    updateUserCategoryColumn : useMutation({ mutationFn: apiUpdate, onSuccess: onUpdate }),
    deleteUserCategoryColumn : useMutation({ mutationFn: apiDelete, onMutate: onDelete }),
  }
}