import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import React from 'react';

const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;

if (BUGSNAG_API_KEY) {
  Bugsnag.start({ apiKey: BUGSNAG_API_KEY, plugins: [ new BugsnagPluginReact() ] });
  BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY });
}

const ErrorBoundary = BUGSNAG_API_KEY && Bugsnag.getPlugin('react').createErrorBoundary(React);

const bugsnagReporting = children => BUGSNAG_API_KEY ? <ErrorBoundary>{ children }</ErrorBoundary> : children;

export default bugsnagReporting;