import { useEffect, useState } from "react";
import useCategories from "../../hooks/useCategories";
import useGlobalParams from "../../hooks/useGlobalParams";
import useCategorySocket from "../../hooks/useCategorySocket";
// import { DateTime } from "luxon";

// Only tasks PENDING or STARTED last updated in the last 24 hours count:
// const taskIsRecentlyPending = task => {
//   if (['PENDING', 'STARTED'].includes(task.state)) {
//     const lastUpdated = DateTime.fromISO(task.last_updated);
//     const diff = DateTime.now().diff(lastUpdated, 'hours').hours;
  
//     return diff < 24;
//   }

//   return false;
// }

export default function LiveUpdates() {
  const { businessId, categoryId } = useGlobalParams();
  
  // const [watchingCategories, setWatchingCategories] = useState([]);
  const [watchingCategory, setWatchingCategory] = useState(null);

  const { data: categories } = useCategories({ businessId, staleTime: 10000 });

  useEffect(() => {
    // setWatchingCategories(categories?.filter(category => category.unchecked_import_tasks?.find(task => taskIsRecentlyPending(task))));
    setWatchingCategory(categories?.find(category => category.id === categoryId));
  }, [categories, categoryId]);

  return (
    // watchingCategories?.map(category => <CategorySocket key={category.id} categoryId={category.id} />)
    watchingCategory && <CategorySocket key={watchingCategory.id} categoryId={watchingCategory.id} />
  );
}

export function CategorySocket({ categoryId }) {
  useCategorySocket({ categoryId });
}
