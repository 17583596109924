import { useContext, useEffect } from "react";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/api";
import AppContext from "../../utils/AppContext";
import Cookies from 'js-cookie';

export default function Logout(props) {

  const navigate = useNavigate();
  const [, dispatch] = useContext(AppContext);

  // const queryFn = () => api.post('/auth/revoke_token', {
  //   client_id       : process.env.REACT_APP_API_CLIENT_ID,
  //   token           : Cookies.get('pl__api_refresh_token'),
  //   token_type_hint : 'refresh_token',
  // });

  const queryFn = () => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_API_CLIENT_ID);
    params.append('token', Cookies.get('pl__api_refresh_token'));
    params.append('token_type_hint', 'refresh_token');
    
    return api.post('/auth/revoke_token/', params);
  }

  const { status } = useQuery({ queryKey: ['logout'], queryFn });

  useEffect(() => {
    // force deletion of cookies even if the status fails
    if (status !== 'loading') {
      dispatch({ type: 'currentUser/delete' });
      const queryClient = new QueryClient();
      queryClient.invalidateQueries();
      queryClient.removeQueries();

      // queries still are inside. Better to force a reload
      // navigate('/');
      window.location.href = '/';
    }
  }, [status, dispatch, navigate]);

  if (status === 'loading') {
    return <div>Logging out...</div>
  }
}
