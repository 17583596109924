import { useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as CheckIcon } from '../../assets/icons_svg/check-mark-outline.svg';
import './LeadDistribution.scss';
import Button from '../../common/Button/Button';
import LeadDistributionModal from './LeadDistributionModal';
import useGlobalParams from '../../hooks/useGlobalParams';
import useCategory from '../../hooks/useCategory';
import useMutateCategory from '../../hooks/useMutateCategory';

export default function LeadDistribution(props) {
  const { businessId, categoryId } = useGlobalParams();

  const [showEdit, setShowEdit] = useState(false);

  const { data: category } = useCategory({ businessId, categoryId });
  const { updateCategory } = useMutateCategory();

  const onChangeDistribution = distributionMode => {
    if (distributionMode === category.lead_distribution_mode) {
      return;
    }

    if (distributionMode === 'custom') {
      setShowEdit(true);

      // do not mutate the category yet as no custom percentage has been set:
      return;
    }

    updateCategory.mutate({ data: { lead_distribution_mode: distributionMode }, id: categoryId });
  }

  const onSaveDistribution = rawDistribution => {
    setShowEdit(false);

    const distribution = {};

    Object.entries(rawDistribution).forEach(([id, value]) => {
      // Only add values higher than 0. Ignore 0, or empty strings. Save is disabled if any value is invalid,
      // or the total sum is not 100%. So we don't need to revalidate rawDistribution:
      if (value > 0) {
        distribution[id] = parseInt(value, 10);
      }
    });

    const data = {
      lead_distribution_mode : 'custom',
      lead_distribution_map  : distribution
    }

    updateCategory.mutate({ data, id: categoryId });
  }

  const showUnassigned = !showEdit && category.lead_distribution_mode === 'unassigned';
  const showAutomatic = !showEdit && category.lead_distribution_mode === 'automatic';
  const showCustom = showEdit || category.lead_distribution_mode === 'custom';

  return (
    <div className="__lead-distribution">
      <h2>Lead Distribution</h2>

      <div className={classNames("option", { selected: showUnassigned })} role='button' onClick={() => onChangeDistribution('unassigned')}>
        { showUnassigned ? <CheckIcon className='check-icon' /> : <div className='radio-box' /> }
        <div className='label-description'>
          <div className="label">Unassigned</div>
          <div className="description">New incoming leads are not assigned to any user by default. Users will need to manually assign each lead.</div>
        </div>
      </div>

      <div className={classNames("option", { selected: showAutomatic })} role='button' onClick={() => onChangeDistribution('automatic')}>
        { showAutomatic ? <CheckIcon className='check-icon' /> : <div className='radio-box' /> }
        <div className='label-description'>
          <div className="label">Automatic</div>
          <div className="description">New incoming leads will be randomly assigned evenly between all users who are part of this category.</div>
        </div>
      </div>


      <div className={classNames("option", { selected: showCustom })} role='button' onClick={() => onChangeDistribution('custom')}>
      { showCustom ? <CheckIcon className='check-icon' /> : <div className='radio-box' /> }
        <div className='label-description'>
          <div className="label">Custom</div>
          <div className="description">Leads will be assigned based on a custom distribution you define.</div>
        </div>
        { showCustom && <Button className='edit-distribution' onClick={() => setShowEdit(true)}>Edit Distribution</Button> }
      </div>

      <LeadDistributionModal show={showEdit} onHide={() => setShowEdit(false)} category={category} onSave={onSaveDistribution} />
    </div>
  );
}
