import USER_COLORS from "../../utils/userColors";

export default function UserColors(props) {
  return (
    <div>
      <h2>User Colors</h2>

      { USER_COLORS.map(color =>
        <div style={{ display: 'flex', margin: 4, gap: 4 }}>
          <div style={{ width: 32, height: 32, backgroundColor: color.code, color: 'white', display: 'flex', borderRadius: 100, fontWeight: 'bold', alignItems: 'center', justifyContent: 'center' }}>AZ</div>
          { color.name } ({ color.code })
        </div>
      )}
    </div>
  )
}
