import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import './Button.scss';

const Button = React.forwardRef(({ loading, disabled, as = 'button', children, ...props }, ref) => {
  const Component = as;

  return (
    <Component disabled={disabled || loading} {...props} ref={ref}>
      { loading ? <Spinner as="span" animation="border" size="sm" /> : children }
    </Component>
  );
});

export default Button;