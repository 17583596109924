import { api } from "../utils/api";
import { Button } from "react-bootstrap";
import { useImmer } from "use-immer";

export default function Health(props) {
  const [responseTime, setResponseTime] = useImmer([]);

  const checkHealth = async () => {
    const startTime = new Date();

    try {
      await api.get('/health/');
      const endTime = new Date();

      setResponseTime(draft => {
        draft.unshift(endTime.getTime() - startTime.getTime());
      });
    }
    catch (e) {
      console.log('error:', e);

      setResponseTime(draft => {
        draft.push('TIMEOUT');
      });
    }
  }

  return (
    <div className="m-4 text-center">
      <Button onClick={checkHealth}>Check Health</Button>
      <pre className="my-2">
        {responseTime.map((time, index) => <div key={index}>Response time... {time === 'TIMEOUT' ? 'TIMEOUT' : `${time}ms`}</div> )}
      </pre>
      <pre className="text-sm my-2">ENDPOINT: {process.env.REACT_APP_API_URL}/health/</pre>
    </div>
  )
}
