import React, { useMemo, useRef } from "react";
import { DateTime } from "luxon";
import classNames from "classnames";

import useMutateLead from "../../hooks/useMutateLead";
import useUsers from "../../hooks/useUsers";
import LEAD_FIELDS, { FIELD_MAX_LENGTH } from "../../utils/leadFields";
import LeadStatusDropdown from "./LeadStatusDropdown";
import LeadAssigneeDropdown from "./LeadAssigneeDropdown";

import Form from 'react-bootstrap/Form';

import TrashIcon from "../../assets/icons/TrashIcon";
import EditableField from "../../common/EditableField/EditableField";

export default function LeadRow({ lead, selected, statuses, organizationId, categoryId, columns, onToggleChange, onClickRow, onDeleteLead, minTableWidth }) {

  const toggleRef = useRef();
  const canToggleClick = useRef();

  const { data: users } = useUsers({ organizationId, categoryId });

  const { updateLeadAsync } = useMutateLead();

  const handleEditLead = data => {
    updateLeadAsync.mutate({ id: lead.id, data });
  }

  const dateAdded = useMemo(() => {
    return DateTime.fromISO(lead.date_added);
  }, [lead.date_added]);

  const lastUpdated = useMemo(() => {
    return DateTime.fromISO(lead.last_updated);
  }, [lead.last_updated]);


  const onClickToggleContainer = e => {
    if (e.target === e.currentTarget) {
      toggleRef.current.click();
    }
  }

  const checkMouseDown = e => {
    const { target, currentTarget } = e;

    let isInteractiveElement = false;
    let element = target;
    
    while (element !== currentTarget) {
      if (['BUTTON', 'INPUT'].includes(element.tagName) || element.dataset.interactive === 'true') {
        isInteractiveElement = true;
        break;
      }

      element = element.parentElement;
    }

    // this prevents user from mousedown (start click) on an input,
    // but finishing the user "click" inside an empty non-interactive part of the row
    // to reproduce, dragging the mouse from an input to outside of the input:
    canToggleClick.current = !isInteractiveElement;
  }
  
  const checkClickRow = e => {
    const { target, currentTarget } = e;
    
    if (!canToggleClick.current) {
      return;
    }
    
    let isInteractiveElement = false;
    let element = target;
    
    while (element !== currentTarget) {
      if (['BUTTON', 'INPUT'].includes(element.tagName) || element.dataset.interactive === 'true') {
        isInteractiveElement = true;
        break;
      }

      element = element.parentElement;
    }
    
    !isInteractiveElement && onClickRow(lead.id);
  }

  const onEditSucces = () => {
    console.log("@YUAN TODO -> decide if show toast or not");
  }

  const renderColumn = column => {

    let field = <EditableField id={lead.id} field={column} initialValue={lead[column]} onEditSucces={onEditSucces} mutation={useMutateLead} updateName='updateLeadAsync' maxLength={FIELD_MAX_LENGTH[column]} />
    
    if (column === 'full_name') {
      field = <EditableField id={lead.id} field={lead.full_name ? 'full_name' : 'first_last_name'} initialValue={lead.full_name || [lead.first_name, lead.last_name]} mutation={useMutateLead} updateName='updateLeadAsync' />
    }
    if (column === 'status') {
      field = <LeadStatusDropdown lead={lead} statuses={statuses} onChange={id => handleEditLead({ status_id: id })} type='discreet' />
    }
    else if (column === 'assigned_user_id') {
      field = <LeadAssigneeDropdown lead={lead} users={users} onChange={id => handleEditLead({ assigned_user_id: id })} type='discreet' />
    }

    else if (column === 'date_added') {
      field = <div className='non-editable'>{ dateAdded.toLocaleString(DateTime.DATE_MED) }</div>
    }

    else if (column === 'last_updated') {
      field = <div className='non-editable'>{ lastUpdated.toLocaleString(DateTime.DATE_MED) }</div>
    }

    const columnFlex = column => LEAD_FIELDS[column].columnWidth * 100 / minTableWidth;

    return (
      <div key={column} className={column} style={{ flex: columnFlex(column) }}>
        { field }
      </div>
    )
  }
  
  return (
    <div key={lead.id} className={classNames('table-row', { selected })} onClick={checkClickRow} onMouseDown={checkMouseDown}>
      <div className='bulk-action'>
        <div className={classNames('toggle-container', { selected })} role='button' onClick={onClickToggleContainer} data-interactive>
          <Form.Check ref={toggleRef} type='checkbox' className='bulk-toggle' checked={selected} onChange={onToggleChange} />
        </div>
      </div>
      { columns?.map(column => renderColumn(column)) }
      <div className='delete-lead'><button className='delete-btn' onClick={onDeleteLead}><TrashIcon /></button></div>
    </div>
  )
}
