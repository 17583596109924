import { useContext, useLayoutEffect } from "react";
import AppContext from "../utils/AppContext";

const addDarkTheme = () => {
  document.documentElement.setAttribute('data-bs-theme', 'dark');
  document.documentElement.setAttribute('data-theme', 'dark');
}

const addLightTheme = () => {
  document.documentElement.removeAttribute('data-bs-theme');
  document.documentElement.removeAttribute('data-theme');
}

export default function useColorTheme() {
  const [state] = useContext(AppContext);

  const { colorTheme } = state;

  useLayoutEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

    const checkTheme = matches => {
      switch (colorTheme) {
        case 'dark':
          addDarkTheme();
          break;

        case 'auto':
          matches ? addDarkTheme() : addLightTheme();
          break;

        case 'light':
        default:
          addLightTheme();
      }
    }
    
    checkTheme(matchMedia.matches);
    matchMedia.addEventListener("change", e => checkTheme(e.matches));
    
  }, [colorTheme]);
}
