import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { api } from "../utils/api";

const apiCreate = ({ data }) => api.post(`/custom-fields/`, { ...data }).then(res => res.data);
const apiUpdate = ({ data, id }) => api.put(`/custom-fields/${id}/`, { ...data }).then(res => res.data);
const apiBulkUpdate = ({ data }) => api.put(`/custom-fields/`, data).then(res => res.data);
const apiDelete = id => api.delete(`/custom-fields/${id}/`).then(res => res.data);

export default function useMutateCustomField({ categoryId }) {
  const queryClient = useQueryClient();

  const onCreate = requestData => {
    queryClient.setQueriesData(['custom-fields', categoryId], data => produce(data, draft => {
      draft.unshift(requestData);
    }));
  }

  const onUpdate = requestData => {
    queryClient.setQueriesData(['custom-fields', categoryId], data => produce(data, draft => {
      const index = data.findIndex(note => note.id === requestData.id);
      draft[index] = requestData;
    }));
  }

  const onBulkUpdate = ({ data: newData }) => {
    queryClient.setQueryData(['custom-fields', categoryId], data => produce(data, draft => {
      newData.forEach(newItem => {
        const index = data.findIndex(item => item.id === newItem.id);
        draft[index] = { ...data[index], ...newItem };
      });
    }));
  }

  const onDelete = id => {
    queryClient.setQueriesData(['custom-fields', categoryId], data => produce(data, draft => {
      const index = data.findIndex(note => note.id === id);
      draft.splice(index, 1);
    }));
  }

  return {
    createCustomField          : useMutation({ mutationFn: apiCreate, onSuccess: onCreate }),
    updateCustomField          : useMutation({ mutationFn: apiUpdate, onSuccess: onUpdate }),
    bulkUpdateCustomFieldAsync : useMutation({ mutationFn: apiBulkUpdate, onMutate: onBulkUpdate }),
    deleteCustomField          : useMutation({ mutationFn: apiDelete, onMutate: onDelete }),
  }
}