import React, { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useImmer } from 'use-immer';
import { arrayMove } from '@dnd-kit/sortable';
import classNames from 'classnames';

import LEAD_FIELDS, { DEFAULT_VISIBLE_FIELDS } from '../../utils/leadFields';
import useUserCategoryColumns from '../../hooks/useUserCategoryColumns';
import useMutateUserCategoryColumn from '../../hooks/useMutateUserCategoryColumn';

import Sortable from '../../common/Sortable/Sortable';
import Button from '../../common/Button/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import './EditColumnsDropdown.scss';

export default function EditColumnsDropdown({ categoryId, show, setShow }) {
  const { data: categoryColumns } = useUserCategoryColumns({ categoryId });
  const { createUserCategoryColumn, updateUserCategoryColumn } = useMutateUserCategoryColumn({ categoryId });
  const [columns, setColumns] = useImmer([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    if (!categoryColumns) {
      return;
    }

    if (show) {
      const selectedColumns = categoryColumns?.[0]?.columns || DEFAULT_VISIBLE_FIELDS.map(column => ({ column, visible: true }));
      const unsetColumns = Object.keys(LEAD_FIELDS).filter(field => !selectedColumns.find(item => item.column === field.toString()));
  
      setColumns([...selectedColumns, ...unsetColumns.map(column => ({ column, visible: false }))]);
    }

  }, [show, categoryColumns, setColumns]);

  if (!categoryColumns) {
    return;
  }

  const onToggleShow = () => {
    setShow(!show);
  }

  const onChangeVisibility = (e, column) => {
    setColumns(draft => {
      const index = draft.findIndex(item => item.column === column);
      draft[index].visible = e.target.checked;
    });

    setError(null);
  }

  const saveCategories = () => {
    const oneColumnChecked = columns.find(column => column.visible);

    if (!oneColumnChecked) {
      setError(true);
      return;
    }

    const id = categoryColumns?.[0]?.id;
    setSaving(true);

    const onSuccess = () => {
      onToggleShow();
      setSaving(false);
    }

    if (id) {
      updateUserCategoryColumn.mutate({ data: { columns }, id }, { onSuccess });
    }

    else {
      const data = {
        category_id : categoryId,
        name        : "Custom Columns",
        active      : true,
        columns,
      }

      createUserCategoryColumn.mutate({ data }, { onSuccess });
    }
  }

  const onChangeOrder = (activeIndex, overIndex) => {
    const newOrder = arrayMove(columns, activeIndex, overIndex);
    setColumns(newOrder);
  }

  const resetCategories = () => {
    const selectedColumns = DEFAULT_VISIBLE_FIELDS.map(column => ({ column, visible: true }));
    const unsetColumns = Object.keys(LEAD_FIELDS).filter(field => !selectedColumns.find(item => item.column === field.toString()));

    setColumns([...selectedColumns, ...unsetColumns.map(column => ({ column, visible: false }))]);
    setError(null);
  }

  const renderField = ({ column, visible }) => {
    return (
      <Sortable.Item id={column}>
        <div className='sortable-column' key={column}>
          <div className="" ref={null}><Sortable.DragHandle /></div>
          <div className='column-name'>{ LEAD_FIELDS[column].label }</div>
          <Form.Check checked={visible} onChange={e => onChangeVisibility(e, column)} />
        </div>
      </Sortable.Item>
    );
  }

  const renderMenuContent = () => (
    <>
      <Sortable items={columns.map(row => ({ id: row.column, ...row }))} renderItem={renderField} onChange={onChangeOrder} />
      <div className='cancel-save'>
        <Button className='cancel' onClick={onToggleShow} disabled={saving}>Cancel</Button>
        <Button className='save' onClick={saveCategories} loading={saving}>Save</Button>
      </div>
      { error && <div className='column-error'>Please select one or more columns</div> }
      <Button className='reset-columns' onClick={resetCategories} disabled={saving}>Reset to default columns</Button>
    </>
  )

  return (
    <>
      <Dropdown className='__edit-columns' show={show} onToggle={saving ? null : onToggleShow}>
        <Dropdown.Toggle as={Toggle}>+</Dropdown.Toggle>
          <Dropdown.Menu align={{ sm: 'end' }} className='__edit-columns'>
            { renderMenuContent() }
          </Dropdown.Menu>
      </Dropdown>

      { createPortal(
        <div className='__edit-columns __edit-columns-drawer'>
          <div className={classNames('__edit-columns-overlay', { '__edit-columns-overlay-show': show })} />
          <div className={classNames('menu-content', { show })} onClick={e => e.stopPropagation() }>
            { renderMenuContent() }
          </div>
        </div>,
        document.getElementById('root')
      )}
    </>
  );
}

const Toggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props} className='__edit-columns-toggle'>
    { children }
  </button>
));