const USER_COLORS = [
  {
    name : 'darkslategray',
    code : '#2f4f4f',
  },
  {
    name : 'darkolivegreen',
    code : '#556b2f',
  },
  {
    name : 'saddlebrown',
    code : '#8b4513',
  },
  {
    name : 'olivedrab',
    code : '#6b8e23',
  },
  {
    name : 'seagreen',
    code : '#2e8b57',
  },
  {
    name : 'midnightblue',
    code : '#191970',
  },
  {
    name : 'slategray',
    code : '#708090',
  },
  {
    name : 'olive',
    code : '#808000',
  },
  {
    name : 'peru',
    code : '#cd853f',
  },
  {
    name : 'steelblue',
    code : '#4682b4',
  },
  {
    name : 'darkslateblue',
    code : '#483d8b',
  },
  {
    name : 'firebrick',
    code : '#b22222',
  },
  {
    name : 'green',
    code : '#008000',
  },
  {
    name : 'rosybrown',
    code : '#bc8f8f',
  },
  {
    name : 'rebeccapurple',
    code : '#663399',
  },
  {
    name : 'teal',
    code : '#008080',
  },
  {
    name : 'chocolate',
    code : '#d2691e',
  },
  {
    name : 'lightseagreen',
    code : '#20b2aa',
  },
  {
    name : 'indianred',
    code : '#cd5c5c',
  },
  {
    name : 'darkblue',
    code : '#00008b',
  },
  {
    name : 'purple2',
    code : '#7f007f',
  },

  {
    name : 'darkorchid',
    code : '#9932cc',
  },
  {
    name : 'orangered',
    code : '#ff4500',
  },
  {
    name : 'maroon3',
    code : '#b03060',
  },
  {
    name : 'mediumblue',
    code : '#0000cd',
  },
  {
    name : 'royalblue',
    code : '#4169e1',
  },
  {
    name : 'crimson',
    code : '#dc143c',
  },
  {
    name : 'blue',
    code : '#0000ff',
  },
  {
    name : 'tomato',
    code : '#ff6347',
  },
  {
    name : 'dodgerblue',
    code : '#1e90ff',
  },
  {
    name : 'deepskyblue',
    code : '#00bfff',
  },
];

export default USER_COLORS;
