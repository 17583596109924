import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getUserCategoryColumns = ({ queryKey }) => {
  const [, categoryId] = queryKey;

  if (!categoryId) {
    console.warn('getUserRoles: missing categoryId');
    return;
  }

  return api.get(`/user-category-columns/?category_id=${categoryId}`).then(res => res.data);
}

export default function useUserCategoryColumns({ categoryId, ...props }) {
  return useQuery({ queryKey: ['user-category-columns', categoryId], queryFn: getUserCategoryColumns, ...props });
}
