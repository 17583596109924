import { useImmerReducer } from 'use-immer';
import AppContext, { APP_INITIAL_STATE, appReducer } from './utils/AppContext';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useColorTheme from './hooks/useColorTheme';
import { IS_DEV } from './utils/envSettings';

import Signup from './routes/Auth/Signup';
import Login from './routes/Auth/Login';
import AuthRoute from './routes/Auth/AuthRoute';
import WIP from './common/WIP';
import Account from './routes/Account/Account';
import Logout from './routes/Auth/Logout';
import LeadsPage from './routes/Leads/LeadsPage';
import CategorySettings from './routes/Settings/CategorySettings';
import Health from './routes/Health';
import ResetPassword from './routes/Auth/ResetPassword';
import Users from './routes/Users/Users';
import BusinessSettings from './routes/Business/BusinessSettings';
import UserColors from './routes/Dev/UserColors';
import OAuthConsent from './routes/Auth/OAuthConsent';
import DevWidget from './common/DevWidget/DevWidget';

import './common/Modal.scss';
import './common/Tooltip.scss';

const LOCAL_OPTIONS = {
  defaultOptions: {
    queries   : { networkMode: 'always' },
    mutations : { networkMode: 'always' }
  }
};

const queryClient = new QueryClient(IS_DEV ? LOCAL_OPTIONS : undefined);

export default function App() {

  const appContext = useImmerReducer(appReducer, APP_INITIAL_STATE);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={appContext}>

        <ColorTheme />
        { IS_DEV && <DevWidget /> }

        <Routes>
          <Route path='health' element={<Health />} />

          {/* PUBLIC ROUTES */}
          <Route path='login'element={<AuthRoute publicOnly element={<Login />} />} />
          <Route path='signup' element={<AuthRoute publicOnly element={<Signup />} />} />
          <Route path='reset' element={<AuthRoute publicOnly element={<ResetPassword />} />} />

          {/* LOGGED-IN ROUTES */}
          <Route path="/" element={<AuthRoute navigation liveUpdates element={<WIP />} />} />
          <Route path="account" element={<AuthRoute navigation liveUpdates element={<Account />} />} />
          <Route path="logout" element={<AuthRoute element={<Logout />} />} />
          <Route path="auth/authorize" element={<AuthRoute element={<OAuthConsent />} />} />

          <Route path="leads" element={<AuthRoute navigation liveUpdates element={<LeadsPage />} />} />
          <Route path="leads/:categoryId" element={<AuthRoute navigation liveUpdates element={<LeadsPage />} />} />
          <Route path="leads/settings/:categoryId" element={<AuthRoute navigation liveUpdates element={<CategorySettings />} />} />

          {/* WIP ROUTES */}
          <Route path="settings" element={<AuthRoute navigation liveUpdates element={<BusinessSettings />} />} />
          <Route path="reporting" element={<AuthRoute navigation liveUpdates element={<WIP />} />} />
          <Route path="users" element={<AuthRoute navigation liveUpdates element={<Users />} />} />

          {/* DEV */}
          <Route path="dev/user-colors" element={<UserColors />} />

          <Route path="no-redirect" element={<AuthRoute noRedirect element={<div>This element should not redirect for logged out users</div>} />} />

          {/* CATCH-ALL */}
          <Route path="*" element={<AuthRoute navigation element={<WIP />} />} />
        </Routes>
        </AppContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </QueryClientProvider>
  );
}

function ColorTheme() {
  useColorTheme();
}
