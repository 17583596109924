import { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import LEAD_FIELDS, { FIELD_MAX_LENGTH, NAME_FIELDS } from '../../utils/leadFields';
import { handleNetworkError } from '../../utils/api';
import { IS_MAC } from '../../utils/envSettings';

import useMutateLead from '../../hooks/useMutateLead';
import useStatuses from '../../hooks/useStatuses';
import useLeadNotes from '../../hooks/useLeadNotes';
import useUsers from '../../hooks/useUsers';
import useCustomFields from '../../hooks/useCustomFields';
import useToast from '../../hooks/useToast';

import PLink from '../../common/Navigation/PLink';
import ConfirmationModal from '../../common/ConfirmationModal/ConfirmationModal';
import LeadStatusDropdown from './LeadStatusDropdown';
import LeadAssigneeDropdown from './LeadAssigneeDropdown';
import Button from '../../common/Button/Button';
import LeadNote from './LeadNote';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Toast from '../../common/Toast/Toast';

import { ReactComponent as InfoIcon } from '../../assets/icons_svg/info.svg';
import TrashIcon from "../../assets/icons/TrashIcon";

import './LeadDetailsModal.scss';

const FIELDS_MAIN = [
  'full_name',
  'first_name',
  'last_name',
  'email',
  'phone',
  'gender',
  'birthdate',
];

const FIELDS_ADDRESS = [
  'country',
  'city',
  'state',
  'zip_code',
  'street_address',
];

export default function LeadDetailsModal({ show, onHide, lead, organizationId, categoryId, defaultStatus, onDelete }) {
  const [editedLead, setEditedLead] = useImmer({});
  const { updateLead, insertMutation } = useMutateLead();
  const { data: statuses } = useStatuses({ categoryId });
  const { data: leadNotes } = useLeadNotes({ leadId: lead?.id });
  const { data: users } = useUsers({ organizationId, categoryId });
  const { data: customFields } = useCustomFields({ categoryId, staleTime: 10000 });
  const [showFullName, setShowFullName] = useState(null);
  const [errors, setErrors] = useState(null);
  const [showEmptyLead, setShowEmptyLead] = useState(false);
  const [newNote, setNewNote] = useState(false);

  const { toast, showToast } = useToast();

  const onInputKeyDown = e => {
    if (e.key === 'Enter' && (IS_MAC ? e.metaKey : e.ctrlKey)) {
      
      // Lead Notes have data-save-shortcut='true' which allows CTRL+Enter to save that note instead of the whole lead
      if (document.activeElement?.dataset.saveShortcut === 'true') {
        return; 
      }

      onSubmit();
    }
  }

  // TODO -> there is a bug. if the lead refreshes, the values will be updated
  // this might be bad UX. Maybe only update the fields that haven't been edited?
  // complex but good
  // same if defaultStatus changes
  useEffect(() => {
    if (show) {
      setEditedLead(lead || {
        status_id: defaultStatus || null,
        custom_fields: {}
      });
      setShowFullName(!lead?.first_name.trim() && !lead?.last_name.trim());
      setErrors(null);
      setNewNote(false);
    }
  }, [show, setEditedLead, lead, defaultStatus]);

  const onSubmit = (ignoreEmptyLead = false) => {
    const data = {};

    if (!ignoreEmptyLead && (!editedLead.first_name?.trim() && !editedLead.last_name?.trim() && !editedLead.full_name?.trim() && !editedLead.phone?.trim() && !editedLead.email?.trim())) {
      setShowEmptyLead(true);
      return; 
    }

    const onError = e => {
      handleNetworkError({ e, setErrors });
    }

    const onSuccess = () => {
      setShowEmptyLead(false);
      onHide();
    }

    [...FIELDS_MAIN, ...FIELDS_ADDRESS, 'status_id', 'assigned_user_id', 'custom_fields'].forEach(field => {
      if (lead?.[field] !== editedLead[field]) {
        data[field] = editedLead[field];
      }
    });

    // check if any custom field was edited. If so, add all the custom fields:
    if (editedLead.custom_fields && Object.entries(editedLead.custom_fields).find(([key, value]) => lead?.custom_fields?.[key] !== value)) {
      data.custom_fields = editedLead.custom_fields;
    }
    
    if (!lead) {
      data.category_id = categoryId;

      if (editedLead.note?.trim().length > 0) {
        data.note = editedLead.note;
      }
      
      insertMutation.mutate({ data }, { onSuccess, onError });
    }
    else {
      if (Object.entries(data).length > 0) {
        updateLead.mutate({ id: lead.id, data }, { onSuccess, onError });
      }
      else {
        onSuccess();
      }
    }
  }

  const renderField = field => {
    let inputField = (
      <>
        <Form.Control type='text' maxLength={FIELD_MAX_LENGTH[field]} value={editedLead[field] || ''} onChange={e => setEditedLead(draft => { draft[field] = e.target.value })} isInvalid={errors?.[field]} onKeyDown={onInputKeyDown} />
        { errors?.[field]?.map(error => <Form.Control.Feedback key={error} type="invalid">{ error }</Form.Control.Feedback> )}
      </>
    );

    if (field === 'status') {
      inputField = <LeadStatusDropdown lead={editedLead} statuses={statuses} onChange={id => setEditedLead(draft => { draft.status_id = id })} />
    }
    else if (field === 'assigned_user_id') {
      inputField = <LeadAssigneeDropdown lead={editedLead} users={users} onChange={id => setEditedLead(draft => { draft.assigned_user_id = id })} />
    }

    return (
      <Form.Group key={field} className='field'>
        <Form.Label>
          <span>{ NAME_FIELDS[field] || LEAD_FIELDS[field].label }</span>
          { ['first_name', 'full_name'].includes(field) && (
            <button className='toggle-name' onClick={() => setShowFullName(!showFullName)}>{ showFullName ? 'use first & last name' : 'use full name' }</button>
          )}
        </Form.Label>
        { inputField }
      </Form.Group>
    )
  }

  const loading = insertMutation.isLoading || updateLead.isLoading;

  const onChangeCustomField = (e, key) => {
    setEditedLead(draft => {
      if (!draft.custom_fields) {
        draft.custom_fields = {};
      }

      draft.custom_fields[key] = e.target.value;
    });
  }

  const filteredMainFields = FIELDS_MAIN.filter(field => {
    if (field === 'full_name') {
      return showFullName;
    }

    else if (field === 'first_name' || field === 'last_name') {
      return !showFullName;
    }
    
    return true;
  });

  const renderNotes = () => {
    const hasNotes = lead && leadNotes?.length > 0;
    
    return (
      <div className='notes'>
        <div className='notes-title'>
          <h3>Notes</h3>
          { hasNotes && !newNote && <button className='add-note' onClick={() => setNewNote(true)}>+ Add</button> }
        </div>

        { lead && !leadNotes?.length && !newNote && (
          <div className='add-first-note'>
            <button onClick={() => setNewNote(true)}>+ Add new note</button>
          </div>
        )}

        { newNote && lead && <LeadNote leadId={lead.id} onCancelNewNote={() => setNewNote(false)} showToast={showToast} /> }

        { !lead && <Form.Control as='textarea' maxLength={1000} rows={3} value={editedLead.note || ''} onChange={e => setEditedLead(draft => { draft.note = e.target.value })} onKeyDown={onInputKeyDown} /> }
        { leadNotes && <div className='all-notes'>{ leadNotes?.map(note => <LeadNote key={note.id} leadId={lead.id} note={note} showToast={showToast} />) }</div> }
      </div>
    )
  }

  const hasCustomFields = customFields?.length > 0 || (editedLead?.custom_fields && Object.keys(editedLead.custom_fields).length > 0);

  const renderCustomFields = () => {
    if (hasCustomFields) {
      return (
        <div className='custom-fields'>
          { customFields?.sort((a, b) => a.order_index - b.order_index).map(field => (
            <Form.Group className='field' key={field.id}>
              <Form.Label>
                <span>{ field.label }</span>
                <CodeTooltip>{ field.code }</CodeTooltip>
              </Form.Label>
              <Form.Control value={editedLead?.custom_fields?.[field.code] || ''} onChange={e => onChangeCustomField(e, field.code)} onKeyDown={onInputKeyDown} />
            </Form.Group>
          ))}
          { editedLead?.custom_fields && Object.entries(editedLead.custom_fields).filter(([key]) => !customFields?.find(field => field.code === key)).map(([key, value]) => (
            <Form.Group className='field' key={key}>
              <Form.Label className='monospace'>{ key }</Form.Label>
              <Form.Control value={value} onChange={e => onChangeCustomField(e, key)} onKeyDown={onInputKeyDown} />
            </Form.Group>
          )) }
        </div>
      )
    }

    else {
      return (
        <div className='add-custom-field'>
          Add custom fields for this category in the <PLink target='_blank' to={`/leads/settings/${categoryId}`}>Settings Page</PLink>
        </div>
      )
    }
  }

  const renderCustomFieldsTooltip = props => (
    <Tooltip {...props} className="__tooltip">
      Custom Fields can be added in the <PLink target='_blank' to={`/leads/settings/${categoryId}`}>Settings Page</PLink> for this category
    </Tooltip>
  );

  const renderSaveTooltip = (props) => <Tooltip className='__tooltip __tooltip-small __tooltip-no-arrow' {...props}>Save ({ IS_MAC ? '⌘' : 'Ctrl' } + Enter)</Tooltip>

  return (
    <>
      <Modal className='__lead-details-modal __modal' show={show && !showEmptyLead} backdrop='static' centered size='xl'>
        <Modal.Header onHide={onHide} closeButton>
          <h2>{ lead ? 'Edit Lead' : 'Add Lead' }</h2>
        </Modal.Header>
        <Modal.Body>
          <div className='main-fields'>
            <div className='field-columns'>
              <div>{ filteredMainFields.map(field => renderField(field)) }</div>
              <div>{ FIELDS_ADDRESS.map(field => renderField(field)) }</div>
            </div>

            <div className='custom-fields-title'>
              <h3>Custom Fields</h3>
              { hasCustomFields && (
                <OverlayTrigger placement="right" delay={{ show: 200, hide: 2000 }} overlay={renderCustomFieldsTooltip}>
                  <InfoIcon className="info-icon" />
                </OverlayTrigger>
              )}
            </div>

            { renderCustomFields() }
          </div>

          <div className='side-fields'>
            { renderField('status') }
            { renderField('assigned_user_id') }
            { renderNotes() }
          </div>
        </Modal.Body>
        <Modal.Footer>
          { lead && (
            <div className='delete-section'>
              <Button className='delete-lead' onClick={onDelete}>
                <TrashIcon />
                <span>Delete Lead</span>
              </Button>
            </div>
          )}
          <Button className='cancel' onClick={onHide}>Cancel</Button>
          <OverlayTrigger placement='top' overlay={renderSaveTooltip} delay={{ show: 300 }}>
            <Button className='confirm' onClick={() => onSubmit(false)} loading={loading}>Save</Button>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>

      <ConfirmationModal title='Empty Lead' show={showEmptyLead} onHide={() => setShowEmptyLead(false)} onConfirm={() => onSubmit(true)} dontShowAgain>
        <p>The lead you're saving has no name, email, or phone number.</p>
        <p><strong>Would you like to {!lead ? 'create' : 'edit' } it anyway?</strong></p>
      </ConfirmationModal>

      <Toast toast={toast} hideClose />
    </>
  );
}

const CodeTooltip = ({ children }) => {

  const renderStatusTooltip = props => (
    <Tooltip {...props} className="__code-tooltip">{ children }</Tooltip>
  );

  return (
    <OverlayTrigger placement="right" delay={{ show: 200, hide: 400 }} overlay={renderStatusTooltip}>
      <InfoIcon className="info-icon" />
    </OverlayTrigger>
  )
}
