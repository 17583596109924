export const LEAD_FIELDS = {
  full_name        : { label: 'Name',           columnWidth : 200 },
  email            : { label: 'Email',          columnWidth : 220 },
  phone            : { label: 'Phone',          columnWidth : 180 },
  status           : { label: 'Status',         columnWidth : 160 },
  assigned_user_id : { label: 'Assigned To',    columnWidth : 160 },
  date_added       : { label: 'Date Added',     columnWidth : 140 },
  last_updated     : { label: 'Last Updated',   columnWidth : 140 },
  country          : { label: 'Country',        columnWidth : 180 },
  state            : { label: 'State',          columnWidth : 140 },
  city             : { label: 'City',           columnWidth : 140 },
  street_address   : { label: 'Street Address', columnWidth : 140 },
  zip_code         : { label: 'Zip Code',       columnWidth : 120 },
  gender           : { label: 'Gender',         columnWidth : 132 },
  birthdate        : { label: 'Birthdate',      columnWidth : 132 },
}

export const NAME_FIELDS = {
  full_name: "Full Name",
  first_name: "First Name",
  last_name: "Last Name",
}

export const FIELD_MAX_LENGTH = {
  full_name      : 64,
  first_name      : 32,
  last_name      : 32,
  email          : 254,
  phone          : 32,
  country        : 64,
  state          : 64,
  city           : 64,
  street_address : 255,
  zip_code       : 16,
  gender         : 16,
  birthdate      : 64,
}

export const DEFAULT_VISIBLE_FIELDS = [
  'full_name',
  'email',
  'phone',
  'status',
  'assigned_user_id',
  'date_added',
];

export const IMPORT_FIELDS = [
  'first_name',
  'last_name',
  'full_name',
  'email',
  'phone',
  'country',
  'state',
  'city',
  'street_address',
  'zip_code',
  'gender',
  'birthdate'
];

export const IMPORT_FIELD_SYNONYMS = {
  "email_address" : "email",
  "contact_email" : "email",
  "mail"          : "email",
  "emial"         : "email",
  "emaill"        : "email",
  "eamil"         : "email",

  "phone_number"   : "phone",
  "contact_number" : "phone",
  "mobile"         : "phone",
  "telephone"      : "phone",
  "cell"           : "phone",
  "phne"           : "phone",
  "phoen"          : "phone",
  "fone"           : "phone",
  "phoone"         : "phone",

  "name"          : "full_name",
  "complete_name" : "full_name",
  "contact_name"  : "full_name",
  "ful_name"      : "full_name",
  "fullname"      : "full_name",
  "fullanme"      : "full_name",

  "fname"      : "first_name",
  "given_name" : "first_name",
  "name_first" : "first_name",
  "frist_name" : "first_name",
  "firstname"  : "first_name",
  "fist_name"  : "first_name",

  "surname"     : "last_name",
  "family_name" : "last_name",
  "lname"       : "last_name",
  "name_last"   : "last_name",
  "lst_name"    : "last_name",
  "lastnme"     : "last_name",
  "lastname"    : "last_name",

  "address"         : "street_address",
  "location"        : "street_address",
  "residence"       : "street_address",
  "mailing_address" : "street_address",
  "address_line"    : "street_address",
  "stret_address"   : "street_address",
  "streetadd"       : "street_address",
  "st_address"      : "street_address",
  "streetadrs"      : "street_address",

  "town"         : "city",
  "municipality" : "city",
  "locality"     : "city",
  "ctiy"         : "city",
  "cit"          : "city",
  "ciyt"         : "city",

  "province"  : "state",
  "region"    : "state",
  "territory" : "state",
  "staet"     : "state",
  "sate"      : "state",
  "stte"      : "state",

  "nation"       : "country",
  "country_name" : "country",
  "contry"       : "country",
  "cuntry"       : "country",
  "counry"       : "country",

  "postal_code" : "zip_code",
  "postcode"    : "zip_code",
  "zipcode"     : "zip_code",
  "zip"         : "zip_code",
  "zipcde"      : "zip_code",
  "zipcoe"      : "zip_code",
  "zi_code"     : "zip_code",
  "zippcode"    : "zip_code",

  "sex"             : "gender",
  "identity"        : "gender",
  "gender_identity" : "gender",
  "gnder"           : "gender",
  "gendr"           : "gender",
  "gend"            : "gender",
  "geneder"         : "gender",

  "dob"           : "birthdate",
  "date_of_birth" : "birthdate",
  "birth_date"    : "birthdate",
  "birthday"      : "birthdate",
  "brithdate"     : "birthdate",
  "birthdte"      : "birthdate",
  "dob_date"      : "birthdate"
}

export default LEAD_FIELDS;
