import React from "react";
import classNames from "classnames";
import Dropdown from 'react-bootstrap/Dropdown';
import ChevronIcon from "../../assets/icons/ChevronIcon";

import './LeadDropdown.scss';

export default function LeadStatusDropdown({ lead, statuses, onChange, type }) {
  
  if (!statuses) {
    return <div>...</div>;
  }

  const selectedStatus = statuses?.find(status => status.id === lead?.status_id);
  
  return (
    <Dropdown className={classNames('__lead-dropdown', { [`__lead-dropdown-${type}`]: type })}>
      <Dropdown.Toggle as={Toggle} className='lead-toggle'>
        <div className="toggle-overflow">
          { selectedStatus && <div className='square' style={{ backgroundColor: `#${selectedStatus.color}` }} /> }
          <div className={classNames('label', { unassigned: !selectedStatus })}>{ selectedStatus?.name || 'No status set' }</div>
          <ChevronIcon className='chevron' />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        { statuses.map(status => (
          <Dropdown.Item key={status.id} as='button' onClick={() => onChange(status.id)}>
            <div className='square' style={{ backgroundColor: `#${status.color}` }} />
            { status.name }
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const Toggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props}>
    { children }
  </button>
));
