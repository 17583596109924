import { ReactComponent as ZapierIcon } from "../../assets/icons_svg/zapier_logo.svg";
import { ReactComponent as PreciuosLeadsIcon } from "../../assets/icons_svg/pl_logo.svg";
import { ReactComponent as ConnectArrow } from "../../assets/icons_svg/connect_arrow.svg";

import './OAuthConsent.scss';
import Button from "../../common/Button/Button";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../utils/api";
import { useSearchParams } from "react-router-dom";

const getScopes = ({ clientId }) => api.get(`/auth/scopes/?client_id=${clientId}`).then(res => res.data);

export default function OAuthConsent(props) {
  const [searchParams] = useSearchParams();

  const clientId = searchParams.get('client_id');
  const redirectUri = searchParams.get('redirect_uri');
  const state = searchParams.get('state');
  const codeChallenge = searchParams.get('code_challenge');

  const { data: scopes } = useQuery({ queryKey: ['scopes', clientId ], queryFn: () => getScopes({ clientId }) });
  
  const onAllow = async () => {
    try {
      const { data } = await api.get(`/auth/authorize/?response_type=code&client_id=${clientId}&state=${state}&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256`);
      
      if (data.redirect_uri) {
        window.location = data.redirect_uri;
      }
    }
    catch (e) {
      console.log('e:', e);
    }
  }

  if (!scopes) {
    return <div>LOADING...</div>
  }

  return (
    <div className='__oauth-consent'>
      <main>
        <h1>Precious Leads</h1>
        <div className='icons'>
          <ZapierIcon className='app-icon' />
          <ConnectArrow />
          <PreciuosLeadsIcon className='app-icon' />
        </div>
        <h2><strong>{ scopes.client_name }</strong> wants to access your <strong>Precious Leads</strong> account</h2>

        <div className="description">
          <p>This will allow <strong>{ scopes.client_name }</strong> to:</p>
          <ul>
            { Object.entries(scopes.scopes).map(([key, scope]) => <li key={key}>{ scope }</li>) }
          </ul>
          {/* <p>You can remove these permissions later by visiting <strong>Settings {'>'} Apps</strong></p> */}
        </div>

        <div className="accept-deny">
          <Button className='button button-cancel' onClick={() => window.close()}>Cancel</Button>
          <Button className='button button-accept' onClick={onAllow}>Allow</Button>
        </div>
      </main>
    </div>
  );
}
