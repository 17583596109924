import { useParams, useSearchParams } from "react-router-dom";

export default function useGlobalParams() {
  const [searchParams] = useSearchParams();

  // NOTE -> category doesn't always come. Only with routesthat include a category id:
  const { categoryId: paramCategoryId } = useParams();

  const organizationId = parseInt(searchParams?.get('organization_id', 10)) || null
  const businessId = parseInt(searchParams?.get('business_id', 10)) || null;
  const categoryId = parseInt(paramCategoryId, 10);  

  return { organizationId, businessId, categoryId }
}
