import Modal from 'react-bootstrap/Modal';
import Button from '../Button/Button';
import useGlobalParams from '../../hooks/useGlobalParams';
import useCategory from '../../hooks/useCategory';
import useMutateImportTask from '../../hooks/useMutateImportTask';

export default function ReviewLeadImportModal({ taskId, show, onHide }) {
  const { businessId, categoryId } = useGlobalParams();

  const { data: category } = useCategory({ businessId, categoryId });
  const { markImportAsReadAsync } = useMutateImportTask({ categoryId });

  const { unchecked_import_tasks } = category || [];

  const task = unchecked_import_tasks?.find(task => task.id === taskId);

  if (!task) {
    return;
  }

  const onSubmit = () => {
    markImportAsReadAsync.mutate({ id: taskId });
    onHide();
  }

  return (
    <Modal show={show} onHide={onHide} className='__modal'>
      <Modal.Header>Review Import</Modal.Header>
      <Modal.Body>
        <div>Errors here</div>
        {/* {task?.errors.map(error => (
          <div key={error}
        ))} */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit}>DONE</Button>
      </Modal.Footer>
    </Modal>
  );
}
