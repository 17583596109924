import React, { createContext, useContext, useMemo, useState } from "react";
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { useSortable, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";

import './Sortable.scss';

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {}
})

export function SortableItem({ children, newlyCreated, id, columnView }) {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({ id });
  
  const context = useMemo(() => ({
    attributes, listeners, ref: setActivatorNodeRef
  }), [attributes, listeners, setActivatorNodeRef]);

  const style = {
    opacity: isDragging ? 0 : undefined,  // set to 0 to not show the bottom item -> was 0.4
    transform: CSS.Translate.toString(transform),
    transition
  }

  return (
    <SortableItemContext.Provider value={context}>
      <li className={classNames("sortable-item", { 'newly-created': newlyCreated, '__sortable-column-view': columnView })} ref={setNodeRef} style={style}>{ children }</li>
    </SortableItemContext.Provider>
  );
}

export function DragHandle() {
  const { attributes, listeners, ref } = useContext(SortableItemContext)

  return (
    <button className="__sortable-drag-handle" {...attributes} {...listeners} ref={ref}>
      <svg viewBox="0 0 20 20" width="12">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </button>
  )
}

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({ styles: { active: { opacity: "0.4" } } }),
}

export function SortableOverlay({ children, columnView }) {
  return <DragOverlay className={classNames("__sortable-dragging-item", { "__sortable-column-item": columnView })} dropAnimation={dropAnimationConfig}>{ children }</DragOverlay>
}

export default function Sortable({ items, onChange, renderItem, columnView }) {
  const [active, setActive] = useState(null);
  const activeItem = useMemo(() => items.find(item => item.id === active?.id), [ active, items ]);

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }));

  const onDragStart = ({ active }) => {
    setActive(active);
  }

  const onDragEnd = ({ active, over }) => {
    if (over && active.id !== over?.id) {
      const activeIndex = items.findIndex(({ id }) => id === active.id);
      const overIndex = items.findIndex(({ id }) => id === over.id);

      onChange(activeIndex, overIndex);
    }

    setActive(null);
  }

  const onDragCancel = () => {
    setActive(null);
  }

  return (
    <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} onDragCancel={onDragCancel}>
      <SortableContext items={items}>
        <ul className={classNames("__sortable")} role="application">
          { items.map(item => <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>) }
        </ul>
      </SortableContext>
      <SortableOverlay columnView={columnView}> { activeItem ? renderItem(activeItem) : null }</SortableOverlay>
    </DndContext>
  );
}

Sortable.Item = SortableItem;
Sortable.DragHandle = DragHandle;
