import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { api } from "../utils/api";

const apiUpdate = ({ data, id }) => api.put(`/import-tasks/${id}/`, { ...data }).then(res => res.data);

export default function useMutateImportTask({ categoryId }) {
  const queryClient = useQueryClient();

  const onMarkAsReadAsync = async ({ id }) => {
    await queryClient.cancelQueries({ queryKey: ['categories'] });
    
    queryClient.setQueriesData(['categories'], data => produce(data, draft => {
      if (Array.isArray(data)) {
        const index = data.findIndex(category => category.id === categoryId);
        const taskIndex = draft[index].unchecked_import_tasks.findIndex(task => task.id === id);

        draft[index].unchecked_import_tasks.splice(taskIndex, 1);
      }
      
      else if (data.id === categoryId) {
        const taskIndex = data.unchecked_import_tasks.findIndex(task => task.id === id);
        draft.unchecked_import_tasks.splice(taskIndex, 1);
      }
    }));
  }

  return {
    markImportAsReadAsync : useMutation({ mutationFn: ({ id }) => apiUpdate({ data: { user_checked: true }, id }), onMutate: onMarkAsReadAsync }),
  }
}