import { userColor, userFullName, userInitials } from '../../utils/userUtils';
import './UserAvatar.scss';

export default function UserAvatar({ user, showName }) {
  return (
    <div className='__user-avatar'>
      <div className='__user-avatar-icon' style={{ backgroundColor: userColor(user).code }}>{ userInitials(user) }</div>
      { showName && <div className='__user-avatar-name'>{ userFullName(user) }</div> }
    </div>
  )
}
